
.success-container {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.651);
}

.success-panel {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 500px;
    height: auto;
    background-color: #0F0F0F;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 10px;
    padding-top: 20px;
    border-radius: 31px;
    box-shadow: inset 0 6px 8px rgba(48, 48, 48, 0.4);
    box-sizing: border-box;
    transition: all 0.4s ease-in-out;
    z-index: 1000;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
}

.close-button {
    display: flex;
    width: 100%;
    height: 54px;
    padding: 10px;
    font-size: 16px;
    color: #a2a2a2;
    background-color: #171717;
    font-family: 'Aeonik', sans-serif;
    font-size: 15px;
    justify-content: center;
    align-items: center;
    border: none;
    border-radius: 21px;
    cursor: pointer;
    box-shadow: inset 0 6px 8px rgba(48, 48, 48, 0.8);
    transition: background-color 0.3s;
  }

  .close-button:hover {
    background-color: #202020;
  }

  .video-container {
    display: flex;
    height: 320px;
    width: 480px;
    background-color: blue;
    overflow: hidden;
    border-radius: 21px;
    margin-top: 10px;
  }

  .success-video {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.success-text-container {
    display: flex;
    flex-direction: column;
    gap: 6px;
    padding-right: 60px;
    padding-left: 10px;
}
