.counter-container {
    display: flex;
    flex-direction: column;
    position: absolute;
    bottom: 70px;
    left: 40px;
    z-index: 1000;
}

.counter-text {
    color: #717171;
    font-size: 14px;
    line-height: 22px;
    text-align: left;
}

@media (max-width: 768px) {
    .counter-container {
        display: none;
    }
  }