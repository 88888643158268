html, body {
  overflow: hidden; /* Prevent horizontal scrolling */
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  background-color: #0F0F0F;
}

/* Prevent text selection across the entire site */
* {
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none;    /* Firefox */
  -ms-user-select: none;     /* Internet Explorer/Edge */
  user-select: none;         /* Standard */
}

/* Allow text selection in input fields */
input, textarea {
  -webkit-user-select: text; /* Safari */
  -moz-user-select: text;    /* Firefox */
  -ms-user-select: text;     /* Internet Explorer/Edge */
  user-select: text;         /* Standard */
}

.App {
  font-family: 'Aeonik', sans-serif;
  background-image: url('./assets/background.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}


.logo {
  position: absolute;
  top: 30px;
  left: 40px;
  width: 40px;
  height: auto;
}

.footer-text {
  position: absolute;
  bottom: 40px;
  left: 40px;
  color: #717171;
  font-size: 14px;
}

.p1 {
  color: #717171;
  font-size: 16px;
  line-height: 22px;
}

.p2 {
  color: #717171;
  font-size: 16px;
  line-height: 22px;
}

.p3 {
  color: #c95000;
  font-size: 16px;
  line-height: 22px;
  font-weight: 500;
}

.b1 {
  color: #9f9f9f;
  font-weight: 600;
  font-size: 16px;
}

.b2 {
  color: #717171;
  font-weight: 600;
  font-size: 16px;
}

hr {
 width: 100%;
 border: 0.4px solid #333333;
}

iframe {
  pointer-events: none; /* Prevent the iframe from capturing mouse events */
  opacity: 0;           /* Hide the iframe */
  display: none;        /* Completely hide the iframe if opacity alone doesn't work */
}

.App-video {
  height: 80%;
  background-color: transparent;
  object-fit: cover;
  z-index: 1;
  transition: width 1.5s ease-in-out, height 0.5s ease-in-out;
  background: none;
  display: block;
  margin: 0 auto; /* Center the video if needed */
}

.collectors-video {
  height: 80%; /* The desired size for the collectors edition */
}



.Background-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -0; /* Place it behind other content */
  opacity: 0.15;
  pointer-events: none;
}

.selector {
  position: absolute;
  bottom: 40px;
  width: 500px;
  height: 52px;
  display: flex;
  justify-content: space-between;
  background-color: #0F0F0F;
  border-radius: 90px;
  gap: 4px;
  padding: 4px;
  box-sizing: border-box;
  box-shadow: inset 0 2px 6px rgba(48, 48, 48, 0.4);
  z-index:10;
}

.selector button {
  flex: 1;
  margin: 0 0px;
  padding: 10px;
  font-size: 16px;
  color: #a2a2a2;
  background-color: #0F0F0F;
  border: none;
  border-radius: 90px;
  cursor: pointer;
  transition: ease-in 0.3s;
  text-align: center;
  align-items: center;
  justify-content: center;
  width: 100%;
  font-family: 'Aeonik', sans-serif;
  font-size: 15px;
}

.selector button:hover {
  background-color: #171717;
  box-shadow: inset 0 6px 8px rgba(48, 48, 48, 0.4);
}

.selector button.active {
  background-color: #171717;
  box-shadow: inset 0 6px 8px rgba(48, 48, 48, 0.4);
}

.top-right-buttons {
  position: absolute;
  top: 30px;
  right: 40px;
  display: flex;
  gap: 10px;
  height: 50px;
}

.sound-button {
  width: 44px;
  height: 44px;
  background-size: cover;
  transition: background-image 0.3s ease-out;
  cursor: pointer;
}

.sound-button.active {
  background-image: url('./assets/sound-button.png');
}

.sound-button.active:hover {
  background-image: url('./assets/sound-button-hover.png');
}

.sound-button.inactive {
  background-image: url('./assets/sound-inactive-button.png');
}

.sound-button.inactive:hover {
  background-image: url('./assets/sound-inactive-button-hover.png');
}


.back-button {
  background-image: url('./assets/back-button.png');
  width: 44px;
  height: 44px;
  background-size: cover;
  transition: background-image 0.3s ease-in-out;
}

.back-button:hover {
  background-image: url('assets/back-button-hover.png');
  cursor: pointer;
}

@media (max-width: 1180px) {
  .edition-text {
    display: none;
  }

  .selector {
    width: 400px;
  }
}

@media (max-width: 768px) {

  .App {
    height: auto; /* Allow the App to grow beyond the viewport height on mobile */
    min-height: 120vh; /* Ensure it takes at least the full viewport height */
    padding-bottom: 20px; /* Add padding at the bottom to avoid content touching the bottom edge */
    display: flex;
    flex-direction: column; /* Stack elements vertically */
    align-items: center;
    justify-content: flex-start;
    overflow-y: auto; /* Enable vertical scrolling on mobile */
    background-image: url('./assets/mobilebackground.png');
  }
  
  .App-video {
    position: absolute;
    top: 8%; /* Align the video to the top */
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    height: auto; /* Maintain aspect ratio */
    max-height: 100vh; /* Ensure video doesn't exceed viewport height */
    background-color: transparent;
  }

  .collectors-video {
    position: absolute;
    top: 15%;
    height: 40%; /* The desired size for the collectors edition */
  }

  .top-right-buttons {
    position: absolute;
    top: 30px;
    right: 20px;
    display: flex;
    gap: 10px;
    height: 50px;
  }

  .logo {
    position: absolute;
    top: 30px;
    left: 20px;
    width: 40px;
    height: auto;
  }

  .selector {
    position: absolute;
    left: 50%; /* Move the panel's left edge to the center of the screen */
    transform: translateX(-50%); /* Shift the panel left by 50% of its width to center it */
    bottom: 20px;
    width: 348px;
    height: 52px;
    display: flex;
    justify-content: space-between;
    background-color: #0F0F0F;
    border-radius: 90px;
    gap: 4px;
    padding: 4px;
    box-sizing: border-box;
    box-shadow: inset 0 2px 6px rgba(48, 48, 48, 0.4);
    z-index:10;
  }
  
  .selector button {
    flex: 1;
    margin: 0 0px;
    padding: 10px;
    font-size: 14px;
    color: #a2a2a2;
    background-color: #0F0F0F;
    border: none;
    border-radius: 90px;
    cursor: pointer;
    transition: ease-in 0.3s;
    text-align: center;
    align-items: center;
    justify-content: center;
    width: 100%;
    font-family: 'Aeonik', sans-serif;
    font-size: 15px;
  }

  .footer-text {
    display: none;
  }

  .edition-text {
    display: none;
  }

}

@font-face {
  font-family: 'Aeonik';
  src: url('./assets/fonts/Aeonik-Light.otf') format('opentype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Aeonik';
  src: url('./assets/fonts/Aeonik-LightItalic.otf') format('opentype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Aeonik';
  src: url('./assets/fonts/Aeonik-Regular.otf') format('opentype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Aeonik';
  src: url('./assets/fonts/Aeonik-RegularItalic.otf') format('opentype');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'Aeonik';
  src: url('./assets/fonts/Aeonik-Bold.otf') format('opentype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Aeonik';
  src: url('./assets/fonts/Aeonik-BoldItalic.otf') format('opentype');
  font-weight: 700;
  font-style: italic;
}

