.failure-container {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.651);
}

.failure-panel {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 340px;
    height: auto;
    background-color: #0F0F0F;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 10px;
    padding-top: 20px;
    border-radius: 31px;
    box-shadow: inset 0 6px 8px rgba(48, 48, 48, 0.4);
    box-sizing: border-box;
    transition: all 0.4s ease-in-out;
    z-index: 1000;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: 14px;
}

.failure-text-container {
    display: flex;
    flex-direction: column;
    padding-left: 10px;
    gap: 8px;
}