
  .purchase-panel {
    position: absolute;
    bottom: 40px;
    right: 30px;
    width: 306px;
    max-height: 100vh; /* Ensure the panel does not exceed the viewport height */
    overflow-y: auto; /* Enable vertical scrolling */
    background-color: #0F0F0F;
    padding: 10px;
    border-radius: 31px;
    box-shadow: inset 0 6px 8px rgba(48, 48, 48, 0.4);
    box-sizing: border-box;
    transition: all 0.4s ease-in-out;
    z-index: 1000;
  }

  /* Hide scrollbar for WebKit browsers */
  .purchase-panel::-webkit-scrollbar {
    width: 0;
    height: 0;
  }

  /* Hide scrollbar for IE, Edge, and Firefox */
  .purchase-panel {
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* IE and Edge */
  }


  .input {
    display: flex;
    height: 42px;
    width: 100%;
    background-color: #0a0a0a;
    color: #949494;
    border-radius: 8px;
    border: none;
    padding-left: 15px;
    font-size: 15px;
    transition: all 0.3s ease;
  }

  .input-row {
    display: flex;
    width: 100%;
    gap: 10px;
  }

  .input-row.full-width {
    width: 100%;
  }

  .input.full-width {
      width: 100%;
  }

  .input:focus, .input:hover {
      border: none;
      outline: solid 0px rgba(255, 255, 255, 0.04);
      background-color: #030303;
  }

  .input::placeholder {
      color: #4e4e4e;
  }

  .purchase-panel.product-details {
    height: 470px;
  }

  .purchase-panel.payment-method {
    height: 220px; /* Let the height be determined by content */
  }

  .purchase-panel.wallet-options {
    height: 426px;
  }

  .purchase-panel.payment-method-card-details {
    height: 290px;
  }


  .purchase-panel.delivery-information {
    height: 590px; /* Adjust this as needed to fit all content */
  }


  hr {
    width: 100%;
  }

  .product-details-container {
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 0 10px;
  }

  .payment-details-container {
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 0 10px;
    margin-bottom: 20px;
  }

  .purchase-panel.collapsed {
    height: 175px; /* Adjust to your collapsed height */
  }

  .purchase-panel:hover {
    cursor: pointer;
  }

  .purchase-window-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    box-sizing: border-box;
    gap: 20px;
  }

  .product-specification {
    display: flex;
    justify-content: space-between;
  }

  .title-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 10px;
    padding: 0 10px;
    margin-top: 10px;
  }

  .title-price {
    display: flex;
    justify-content: space-between;
  }

  .product-details, .input-details {
    margin-top: 1em;
  }

  .input-details {
    display: flex;
    flex-direction: column;
    gap: 10px;
    height: auto;
    padding: 0 6px;
    margin-bottom: 10px;
    box-sizing: border-box;
  }
  
  .input-details.card {
    display: flex;
    flex-direction: column;
    gap: 10px;
    height: auto;
    padding: 0 6px;
    margin-bottom: 20px;
    box-sizing: border-box;
  }

  .purchase-window-container-card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    box-sizing: border-box;
    gap: 0px;
  }


  .purchase-btn:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
  
  .pre-order-btn, .ada-btn, .card-btn, .nami-btn, .typhon-btn, .eternl-btn, .flint-btn, .vespr-btn, .purchase-btn {
    background-color: antiquewhite;

  }

  .pre-order-btn {
    display: flex;
    width: 400px;
    
  }

  .payment-option-container {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  
  .purchase-panel button {
    display: flex;
    width: 100%;
    height: 54px;
    padding: 10px;
    font-size: 16px;
    color: #a2a2a2;
    background-color: #171717;
    font-family: 'Aeonik', sans-serif;
    font-size: 15px;
    justify-content: center;
    align-items: center;
    border: none;
    border-radius: 21px;
    cursor: pointer;
    box-shadow: inset 0 6px 8px rgba(48, 48, 48, 0.8);
    transition: background-color 0.3s;
  }
  
  .purchase-panel button:hover {
    background-color: #202020;
  }
  
  .purchase-panel button:disabled {
    cursor: not-allowed;
  }

  .purchase-panel .purchase-back-btn {
    background-image: url('./assets/purchase-back-btn.png');
    width: 12px !important;
    height: 12px !important; /* Adjust the height as needed */
    background-size: cover;
    border: none;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    display: inline-block; /* Ensure it is displayed correctly */
    box-shadow: none;
    background-color: #03030300;
    box-sizing: border-box;
    padding: none;
  }

  .purchase-panel .purchase-back-btn:hover {
    background-image: url('./assets/purchase-back-btn.png');
    opacity: 0.5;
    background-color:#03030300;
  }



  @media (max-width: 768px) {
    .purchase-panel {
      position: absolute;
      bottom: 84px;
      left: 50%; /* Move the panel's left edge to the center of the screen */
      transform: translateX(-50%); /* Shift the panel left by 50% of its width to center it */
      width: 350px;
      height: 500px;
      background-color: #0f0f0fcc;
      padding: 10px;
      border-radius: 31px;
      box-shadow: inset 0 6px 8px rgba(48, 48, 48, 0.4);
      box-sizing: border-box;
      transition: all 0.4s ease-in-out;
      z-index: 1000;
      backdrop-filter: blur(10px); /* Apply background blur effect */
      -webkit-backdrop-filter: blur(10px); /* Safari support */
    }


    .purchase-panel.collapsed {
      height: 154px; /* Adjust to your collapsed height */
    }

    .purchase-panel.product-details {
      height: 470px;
    }
  
    .purchase-panel.payment-method {
      height: 220px; /* Let the height be determined by content */
    }
  
    .purchase-panel.wallet-options {
      height: 410px;
    }
  
    .purchase-panel.delivery-information {
      height: 540px; /* Adjust this as needed to fit all content */
    }
  
  }